export default defineNuxtRouteMiddleware(async (to, from) => {
  
    // let result = await $fetch(`https://cdn.babybull.tech/projects?address=0x0`, {
    //     method: 'GET',
    //     redirect: 'follow'
    // })

    // // console.log(result)

    // result = result?.map((p) => {
    //   return {
    //     //...p,
    //     project_id: p.project_id,
    //     project_name: p.name,
    //     project_logo: p.logo,
    //     project_banner: p.banner,
    //     project_description: p.description,
    //     project_ath: p.all_time_high,
    //     project_tier_allocation: p.tier_allocations,
    //     is_ushi_private: p.is_ushi_private,
    //     project_status: p.status,
    //     project_logo: p.logo?.split('/')?.pop(),
    //     project_banner: p.banner?.split('/')?.pop(),
    //     project_tags: p.tags,
    //     project_index: p.index,
    //     project_roi: p.roi,
    //   }
    // })

    //   let categories = result?.map((p) => p.project_status)
    //   let projectByCategories = {}

    //   categories?.forEach((c) => {
    //     projectByCategories[String(c).toLowerCase()] = {
    //       'projects': result?.filter((p) => p.project_status === c)
    //     }
    //   })


    // const landingProjectState = useState('landingProject')
    // landingProjectState.value = projectByCategories

    const { $fetchProject } = useNuxtApp();
    await $fetchProject();
  
  });  
